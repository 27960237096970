import React, { ReactElement } from 'react';
import { IoMdReturnRight } from 'react-icons/io';
import ImageButtonWithLabel from './image-button-with-label';
import Button from './ui/button';
import Text from './ui/text';
import Image from "next/legacy/image";
import Link from 'next/link';
import IndexText from './index-text';
import SolutionsCalloutText from '@blocks/SolutionsCalloutText';

interface Props {
  index: number;
  slug?: string;
  title: string;
  description: string;
  imageUrl: string;
  href?: string | null;
}

function SolutionListItem({
  index,
  slug,
  href = null,
  title,
  description,
  imageUrl,
}: Props): ReactElement {
  return <>
    {index === 0 ? (
      <SolutionsCalloutText text="Give your business a march on nefarious actors." />
    ) : index === 1 ? (
      <SolutionsCalloutText text="Delivering critical infrastructure protection and smarter data intelligence." />
    ) : (
      <div className="hidden lg:block col-span-4" />
    )}

    <div className="group col-span-4 bg-black">
      <div className="relative min-h-[280px] xl:min-h-[300px]">
        <div className="absolute inset-0 z-10 mix-blend-soft-light bg-gradient-to-tr from-telesoft to-transparent" />
        <div className="z-10 absolute inset-0 bg-gradient-t-r from-telesoft to-transparent mix-blend-soft-light" />
        <Image
          src={imageUrl}
          layout="fill"
          alt={title}
          className="object-cover object-center group-hover:scale-105 transition-transform duration-1000"
        />
        <ImageButtonWithLabel
          label={'View'}
          link={slug ? `/solutions/${slug}` : href ? href : '#!'}
          hideLabel={true}
          variant="right"
        />

        <div className="absolute bg-black text-white xl:text-black inset-0 bg-opacity-50 left-0 flex flex-col p-10 right-0 lg:top-[280px] lg:bg-transparent z-20 lg:min-h-[312px] xl:top-[300px] xl:min-h-[332px] lg:group-hover:bg-accent-1 border-b border-black transition-colors duration-300">
          <IndexText index={index} />
          <div className="flex justify-start items-start max-w-lg">
            <IoMdReturnRight className="text-xl text-white lg:group-hover:text-black lg:text-black mr-2 mt-3 xl:mt-[18px]" />
            <Text
              variant="mediumHeading"
              className="text-white lg:group-hover:text-black lg:text-black mr-2 mt-2"
            >
              {title}
            </Text>
          </div>

          <div className="flex flex-col justify-start items-start mt-0 md:mt-1 xl:mt-2 text-white lg:group-hover:text-black lg:text-black">
            <Text className="max-w-xs">{description}</Text>

            <Link
              passHref
              href={slug ? `/solutions/${slug}` : href ? href : '#!'}
              legacyBehavior>
              <Button
                className="mt-2 md:mt-4 xl:mt-6 text-white lg:group-hover:text-black lg:text-black"
                invert={true}
                variant="cta"
              >
                Learn More
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  </>;
}

export default SolutionListItem;
