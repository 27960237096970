import React, { ReactElement } from 'react';
import Image from "next/legacy/image";
import Link from 'next/link';

import Grid from '@components/grid';
import Text from '@components/ui/text';
import { IoMdReturnRight } from 'react-icons/io';
import SuperHeadingHero from '../../blocks/SuperHeadingHero';
import Container from '@components/ui/container';

interface Props {
  title: string;
  description: {
    short: string;
    long: string;
  };
  imageUrl: string;
  includeLinks?: boolean;
}

function ProductLinkItem({ title, href }: { title: string; href: string }) {
  return (
    <Link passHref href={href} legacyBehavior>
      <button className="group flex justify-start items-center">
        <IoMdReturnRight className="mr-4 lg:text-xl" />
        <Text variant="heading" className="group-hover:underline">
          {title}
        </Text>
      </button>
    </Link>
  );
}

export default function SolutionsHeroSection({
  title,
  description,
  imageUrl,
  includeLinks = true,
}: Props): ReactElement {
  return (
    <>
      <SuperHeadingHero title={title} subTitle={description.short} />
      <Container>
        <div className="group w-full relative mt-8 mb-4">
          {/* <div className="z-10 absolute inset-0 bg-gradient-to-b from-telesoft to-transparent mix-blend-soft-light" /> */}
          <Image
            src={imageUrl}
            alt={title}
            width={1600}
            height={280}
            className="object-cover object-top group-hover:scale-105 bg-black transition-transform duration-1000"
          />
        </div>

        {includeLinks && (
          <Grid>
            <div className="col-span-full text-black lg:bg-accent-1 lg:p-8 flex flex-col justify-start items-start">
              <h5>RETURN LINKS</h5>
              <div className="w-full h-[1px] bg-black my-4" />
              <ProductLinkItem href="/" title="Home" />
              <ProductLinkItem href="/products" title="Our Products" />
              <ProductLinkItem href="/solutions" title="Our Solutions" />
            </div>
          </Grid>
        )}
      </Container>
    </>
  );
}
